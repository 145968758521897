<template>
    <el-container class="layout_container">
        <el-aside :width="nav_width" class="aside">
            <index-meu-component class="menu"></index-meu-component>
        </el-aside>
        <el-container>
            <el-header>
                <index-header-component></index-header-component>
            </el-header>
            <el-main class="main">
                <!-- 子路由出口 -->
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import '@/assets/style/index/index_view.css'
import { my_information_request } from '@/network/user'
import { auth_list } from '@/network/auth'
import IndexMeuComponent from '@/components/index/IndexMeuComponent'
import IndexHeaderComponent from '@/components/index/IndexHeaderComponent'

export default {
    name: 'IndexView',
    data() {
        return {
            user: {},
        }
    },
    computed: {
        nav_width() {
            return this.$store.state.isCollapse ? 'auto' : '260px'
        },
    },
    methods: {
        get_my_information() {
            my_information_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.$store.commit('edit_user', s.result)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        get_auth_list() {
            auth_list()
                .then((s) => {
                    if (s.status === 0) {
                        this.$store.commit('edit_auth_list', s.result)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
    },
    filters: {},
    props: {},
    created() {
        this.get_my_information()
        this.get_auth_list()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        IndexMeuComponent,
        IndexHeaderComponent,
    },
}
</script>