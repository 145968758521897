<template>
    <el-menu
        :default-active="this.$route.fullPath"
        background-color="#1a1f20"
        text-color="#e5e5e5"
        active-text-color="#ffd04b"
        :collapse="this.$store.state.isCollapse"
        router
    >
        <el-menu-item index="/">
            <i class="font_family al-icon-shouye"></i>
            <span slot="title">首页</span>
        </el-menu-item>

        <el-submenu
            v-for="v_model in menu"
            :index="v_model.name"
            :key="v_model.name"
        >
            <template slot="title">
                <i :class="'font_family '+v_model.icon"></i>
                <span>{{ v_model.name }}</span>
            </template>
            <el-submenu
                v-for="v_iteam in v_model.child"
                :index="v_iteam.name"
                :key="v_iteam.name"
            >
                <template slot="title">
                    <i :class="'font_family '+v_iteam.icon"></i>
                    <span>{{ v_iteam.name }}</span>
                </template>
                <el-menu-item
                    v-for="v_route in v_iteam.child"
                    :index="'/' + v_route.router"
                    :key="v_route.name"
                >
                    <i :class="'font_family '+v_route.icon"></i>
                    <span>{{ v_route.name }}</span>
                </el-menu-item>
            </el-submenu>
        </el-submenu>
    </el-menu>
</template>

<script>
import { menu_request } from '@/network/user'

export default {
    name: 'IndexMeu',
    data() {
        return {
            menu: {},
        }
    },
    computed: {},
    methods: {
        get_menu() {
            menu_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.menu = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
    },
    filters: {},
    props: {},
    created() {
        this.get_menu()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
}
</script>

<style>
.el-menu .font_family{
    margin-right: 10px;
    }
</style>