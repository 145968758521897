<template>
    <div class="header">
        <div style="display: flex;align-items: center;">
            <div>
                <img
                    v-if="this.$store.state.user.head_portrait"
                    :src="this.$store.state.baseURL + this.$store.state.user.head_portrait"
                    @click="changeIsCollapse"
                    class="avatar"
                />
            </div>
            <div>
                <span>{{ this.$store.state.user.enterprise_name }}-</span>
                <span>{{ this.$store.state.user.department_name }}-</span>
                <span>{{ this.$store.state.user.position_name }}-</span>
                <span>{{ this.$store.state.user.name }}</span>
            </div>
        </div>
        <div class="my_menu">
            <el-button v-if="$_has('我的信息')" effect="plain" :hit="true" class="item" @click="GoMyInformation">我的信息</el-button>
            <el-button v-if="$_has('我的申请')" effect="plain" :hit="true" class="item" @click="GoMyApply"><el-badge :value="ApplyNum" :max=99 class="itemm" >我的申请</el-badge></el-button>
            <el-button v-if="$_has('我的审批')" effect="plain" :hit="true" class="item" @click="GoMyExamine"><el-badge :value="ExamineNum" :max=99 class="itemm">我的审批</el-badge></el-button>
            <el-button v-if="$_has('我的抄送')" effect="plain" :hit="true" class="item" @click="GoMyCc"><el-badge :value="CcNum" :max=99 class="itemm">我的抄送</el-badge></el-button>
            <el-button type="danger" plain  @click="GoOut">退 出</el-button>
        </div>
    </div>
</template>

<script>
import '@/assets/style/index/index_header.css'
import { my_notice_num_request} from '@/network/user.js'

export default {
    name: 'IndexHeader',
    data() {
        return {
            ApplyNum: '',
            ExamineNum: '',
            CcNum: '',
            lunxun: true
        }
    },
    computed: {},
    methods: {
        //改变折叠状态
        changeIsCollapse() {
            this.$store.commit(
                'edit_is_collapse',
                !this.$store.state.isCollapse
            )
        },
        GoOut() {
            this.$confirm('是否确定退出系统？', '友情提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.lunxun = false
                    sessionStorage.removeItem('x-token')
                    this.$router.replace('/login')
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        GoMyInformation() {
            this.$router.push('/MyInformation')
        },
        GoMyApply() {
            this.$router.push('/MyApply')
        },
        GoMyExamine() {
            this.$router.push('/MyExamine')
        },
        GoMyCc() {
            this.$router.push('/MyCc')
        },
        get_notice_num() {
            const changeSendTime = setInterval(()=>{
                if(this.lunxun) {
                    my_notice_num_request()
                        .then((s) => {
                            this.loading = false
                            if (s.status === 0) {
                                this.ApplyNum = s.result.my_apply
                                this.ExamineNum = s.result.my_examine
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.loading = false
                            this.$message.error(err)
                        })
                } else {
                    clearInterval(changeSendTime)
                }
            },5000)
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_notice_num()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
}
</script>
<style >  
.header{
    font-family: PingFang SC;
    color: #535355;
}
.el-badge__content.is-fixed {
  top: -5px !important;
  right: 5px;
}
.el-badge__content {
    font-size: 10px !important;
}
</style>