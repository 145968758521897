import request from './request'

//获取用户拥有的权限
export const auth_list = () => {
    return request({
        method:'GET',
        url:'auth_list'
    })
}

//获取树形主权限列表
export const all_main_auth_request = () => {
    return request({
        method:'GET',
        url:'get_all_main_auth'
    })
}

//获取职位下的主权限
export const position_main_auth_request = id => {
    return request({
        method:'GET',
        url:'get_position_main_auth',
        params: {
            id
        }
    })
}

//获取树形查看权限列表
export const all_look_auth_request = () => {
    return request({
        method:'GET',
        url:'get_all_look_auth'
    })
}

//获取职位下的查看权限
export const position_look_auth_request = id => {
    return request({
        method:'GET',
        url:'get_position_look_auth',
        params: {
            id
        }
    })
}

//职位授权
export const edit_position_main_auth_request = data => {
    return request({
        method:'PUT',
        url:'position/edit_auth',
        data
    })
}